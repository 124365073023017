import Advertisement from "@/commons/ui/components/advertisement/Advertisement";
// import { checkEnabledADV } from "@/commons/ui/components/advertisement/Advertisement";
import { AdsSlotsContants } from "@/commons/ui/constants/AdsConstants";
// import BreakPointUtility from "@/commons/utilities/BreakPointUtility";
// import LocationUtility from "@/commons/utilities/LocationUtility";
import styles from "./Ads.module.scss";

// import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

// import { useSelector } from "react-redux";
// import { ccontextSelector } from "@/commons/redux/selectors/ccontext";

const MainAdv = ({ notRenderADV = false }) => {
  // const { rStateIsBot, rStateHeaders } = useSelector(ccontextSelector);
  // const isLocalPage = LocationUtility.isLocalPage();
  // const userLocation = LocationUtility.getUserLocation();
  // const location = isLocalPage
  //   ? LocationUtility.getUrlLocation()
  //   : userLocation;

  let slot = AdsSlotsContants.MAST_PG;

  // if (BreakPointUtility.isMobile(rStateHeaders))
  //   slot = AdsSlotsContants.MASTHEAD_WEB_MOBILE;
  // else if (BreakPointUtility.isTablet(rStateHeaders))
  //   slot = AdsSlotsContants.MASTHEAD_WEB_TABLET;
  // if (location.default || !checkEnabledADV(slot, rStateIsBot)) return null;

  const additionalConfs = {
    targeting: [],
    disableInitialLoad: true,
  };

  return (
    <div className={styles.mainAdv}>
      {!notRenderADV && (
        <Advertisement slot={slot} additionalConfs={additionalConfs} />
      )}
    </div>
  );
};

export default MainAdv;
